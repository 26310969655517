import React from "react";
import Four0FourCard from "@andertons/design-system/components/404Card";

export default function NotFound() {
  return (
    <div className="w-screen h-full flex items-center justify-center">
      <Four0FourCard />
    </div>
  );
}
